import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { AlertService } from "./alert.service";
import { environment } from '../../../environments/environment';
import { forkJoin } from "rxjs";

@Injectable()
export class SystemService {

    public token : string;
    constructor(private http: HttpClient, private alertService: AlertService){ }

    getMenuModules(filter:object = {}){
        let headers = new HttpHeaders({'Content-Type':  'application/json','Authorization' : 'Bearer '+localStorage.getItem('userToken')});
        return this.http.post(
              environment.apiCredentials.apiDomain+'/system/getMenuModules',
              JSON.stringify({
              }),
              { headers: headers }
        );
    }

    saveMenuModule(menuModuleObj){
        let postEvent = 'addMenuModule';
        if(menuModuleObj.menuModuleId !== null){
            postEvent = 'updateMenuModule';
        }
        let headers = new HttpHeaders({'Content-Type':  'application/json','Authorization' : 'Bearer '+localStorage.getItem('userToken')});
        return this.http.post(
              environment.apiCredentials.apiDomain+'/system/'+postEvent,
              JSON.stringify(menuModuleObj),
              { headers: headers }
        );
    }

    deleteMenuModule(menuModuleObj){
        let headers = new HttpHeaders({'Content-Type':  'application/json','Authorization' : 'Bearer '+localStorage.getItem('userToken')});
        return this.http.post(
              environment.apiCredentials.apiDomain+'/system/deleteMenuModule',
              JSON.stringify(menuModuleObj),
              { headers: headers }
        );
    }

    getMenuActions(filter:object = {}){
        let headers = new HttpHeaders({'Content-Type':  'application/json','Authorization' : 'Bearer '+localStorage.getItem('userToken')});
        return this.http.post(
              environment.apiCredentials.apiDomain+'/system/getMenuActions',
              JSON.stringify(filter),
              { headers: headers }
        );
    }

    saveMenuAction(menuActionObj){
        let postEvent = 'addMenuAction';
        if(menuActionObj.menuActionId !== null){
            postEvent = 'updateMenuAction';
        }
        let headers = new HttpHeaders({'Content-Type':  'application/json','Authorization' : 'Bearer '+localStorage.getItem('userToken')});
        return this.http.post(
              environment.apiCredentials.apiDomain+'/system/'+postEvent,
              JSON.stringify(menuActionObj),
              { headers: headers }
        );
    }

    deleteMenuAction(menuActionObj){
        let headers = new HttpHeaders({'Content-Type':  'application/json','Authorization' : 'Bearer '+localStorage.getItem('userToken')});
        return this.http.post(
              environment.apiCredentials.apiDomain+'/system/deleteMenuAction',
              JSON.stringify(menuActionObj),
              { headers: headers }
        );
    }

    getMenus(filter:object = {}){
        let headers = new HttpHeaders({'Content-Type':  'application/json','Authorization' : 'Bearer '+localStorage.getItem('userToken')});
        return this.http.post(
              environment.apiCredentials.apiDomain+'/system/getMenus',
              JSON.stringify(filter),
              { headers: headers }
        );
    }

    saveMenu(menuObj){
        let postEvent = 'addMenu';
        if(menuObj.menuId !== null){
            postEvent = 'updateMenu';
        }
        let headers = new HttpHeaders({'Content-Type':  'application/json','Authorization' : 'Bearer '+localStorage.getItem('userToken')});
        return this.http.post(
              environment.apiCredentials.apiDomain+'/system/'+postEvent,
              JSON.stringify(menuObj),
              { headers: headers }
        );
    }

    deleteMenu(menuObj){
        let headers = new HttpHeaders({'Content-Type':  'application/json','Authorization' : 'Bearer '+localStorage.getItem('userToken')});
        return this.http.post(
              environment.apiCredentials.apiDomain+'/system/deleteMenu',
              JSON.stringify(menuObj),
              { headers: headers }
        );
    }

    getMenuFormData() : Observable<any[]> {
        let headers = new HttpHeaders({'Content-Type':  'application/json','Authorization' : 'Bearer '+localStorage.getItem('userToken')});
        let appModuleResponse = this.getModules({'status':'B'});
        let menuModulesResponse = this.getMenuModules({'status':'A'});
        let menuActionResponse = this.getMenuActions({'status':'A'});
        return forkJoin([appModuleResponse,menuModulesResponse,menuActionResponse]);
    }

    // Following call is for getting the list of app modules 
    getModules(filter:object = {}) {
        let headers = new HttpHeaders({'Content-Type':  'application/json','Authorization' : 'Bearer '+localStorage.getItem('userToken')});
        return this.http.post(
              environment.apiCredentials.apiDomain+'/system/getModules',
              JSON.stringify(filter),
              { headers: headers }
        );
    }

    // Following call is getting the Client list for client autocomplete.
    getClientsForAutoComplete(filter:object = {}) {
        let headers = new HttpHeaders({'Content-Type':  'application/json','Authorization' : 'Bearer '+localStorage.getItem('userToken')});
        return this.http.post(
              environment.apiCredentials.apiDomain+'/system/getClientsForAutoComplete',
              JSON.stringify(filter),
              { headers: headers }
        );
    }

    getClientModuleAccessFormData() : Observable<any[]> {
        let headers = new HttpHeaders({'Content-Type':  'application/json','Authorization' : 'Bearer '+localStorage.getItem('userToken')});
        let clientsResponse = this.getClientsForAutoComplete({'status':'A'});
        let appModuleResponse = this.getModules({'status':'A'});
        return forkJoin([clientsResponse,appModuleResponse]);
    }
}
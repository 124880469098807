<!-- The Modal -->
<div id="myModal" class="modal" *ngIf="modalStatus" >
    <!-- Modal content -->
    <div class="modal-content" [@dialog] >
        <div class="header" [ngClass]="{ 'alertInfo': type=='info', 'alertError': type=='error', 'alertSuccess': type=='success'}">
            <h3 >{{title}}</h3>
            <span class="close" (click)="closeAlert()">&times;</span>
        </div>

        <div class="body">
            <p>{{body}}</p>
            <hr>
        </div>
        <div class="footer">
            <span class="footer-btn">
                
                <button class="btn btn-default" (click)="closeAlert()">OK</button>
            </span>
        </div>
    </div>
</div>
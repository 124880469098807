import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { FormControl, FormGroup, FormArray } from '@angular/forms';
import { Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { AlertService } from "./alert.service";
import { environment } from '../../../environments/environment';
import { ListActionsComponentService } from "./list-actions-component.service";
@Injectable()
export class CommonService {

      public token : string = "Test Token";
      constructor(private http: HttpClient, private router: Router, private alertService: AlertService, private listActionsComponentService: ListActionsComponentService){ }

      apiAuthenticate(){
            let headers = new HttpHeaders({'Content-Type':  'application/json'});
            return this.http.post(
                  environment.apiCredentials.apiDomain+'/common/apiAuth',
                  JSON.stringify({
                        user:environment.apiCredentials.user,
                        pass:environment.apiCredentials.pass,
                        key:environment.apiCredentials.key
                  }),
                  { headers: headers }
            ).pipe(
                  retry(2),
            );
      }

      userAuthentication(user){
            let headers = new HttpHeaders({'Content-Type':  'application/json','Authorization' : 'Bearer '+localStorage.getItem('apiToken')});
            return this.http.post(
                  environment.apiCredentials.apiDomain+'/common/userAuth',
                  JSON.stringify({
                        clientId:user.clientId,
                        username:user.username,
                        password:user.password
                  }),
                  { headers: headers }
            ).pipe(
                  retry(2),
            );
      }

      userTokenAuthentication(){
            let headers = new HttpHeaders({'Content-Type':  'application/json','Authorization' : 'Bearer '+localStorage.getItem('userToken')});
            return this.http.post(
                  environment.apiCredentials.apiDomain+'/common/userSessionAuth',
                  JSON.stringify({
                  }),
                  { headers: headers }
            ).pipe(
            );
      }

      getUserMenus(forModuleId){
            let headers = new HttpHeaders({'Content-Type':  'application/json','Authorization' : 'Bearer '+localStorage.getItem('userToken')});
            return this.http.post(
                  environment.apiCredentials.apiDomain+'/common/getUserMenus',
                  JSON.stringify({
                        "forModuleId":forModuleId
                  }),
                  { headers: headers }
            ).pipe(
            );
      }

      updateCurrentSelectionData(currentSelectionData){
            let headers = new HttpHeaders({'Content-Type':  'application/json','Authorization' : 'Bearer '+localStorage.getItem('userToken')});
            return this.http.post(
                  environment.apiCredentials.apiDomain+'/common/updateUserCurrentSelection',
                  JSON.stringify({
                        "currentSelectionData":currentSelectionData
                  }),
                  { headers: headers }
            ).pipe(
            );
      }

      logout(){
            let headers = new HttpHeaders({'Content-Type':  'application/json','Authorization' : 'Bearer '+localStorage.getItem('userToken')});
            return this.http.post(
                  environment.apiCredentials.apiDomain+'/common/userLogout',
                  JSON.stringify({
                  }),
                  { headers: headers }
            ).pipe(
                  retry(2),
            );
      }

      redirectToLogin():void{
            this.alertService.create("Session Expired","error",20,"Your session expired, please login again.",()=>{
                  localStorage.removeItem('userToken');
                  localStorage.removeItem('isLogged');
                  localStorage.removeItem('userData');
                  localStorage.removeItem('currentBranchData');
                  localStorage.removeItem('currentCompanyData');
                  localStorage.removeItem('currentModuleData');
                  localStorage.removeItem('currentFinancialYear');
                  localStorage.removeItem('menuActionsData');
                  //console.log('callback');
                  this.router.navigate(['/login']);
                  
            });
      }

      subscribeMenuActionsToEvent(actionDataForMenu,forMenu):Array<any>{
            let actionSubscription:Array<any> = [];
            if(actionDataForMenu){
                  for(let [key,actObj] of Object.entries(actionDataForMenu)){
                        //console.log(actObj['clickEvent']);
                        actionSubscription[key] = this.listActionsComponentService.on(actObj['clickEvent']).subscribe(()=> forMenu[`${actObj['clickEvent']}`]());
                  }
            }
            return actionSubscription;
      }

      unSubscribeMenuActions(subscriberObj){
            for(let k in subscriberObj){
                  subscriberObj[k].unsubscribe();
            }
      }

      setAsTouched(group: FormGroup | FormArray) {
            group.markAsTouched()
            for (let i in group.controls) {
                  if (group.controls[i] instanceof FormControl) {
                        group.controls[i].markAsTouched();
                  } else {
                        this.setAsTouched(group.controls[i]);
                  }
            }
      }
}
import { Component, Output, EventEmitter, ViewChild, ElementRef  } from '@angular/core';
import { CommonService } from './shared/services/common.service';
import { AlertService } from "./shared/services/alert.service";
export interface menustructure {
  title: string;
  template: any;
  status: boolean;
  code: string;
}
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  constructor(private commonService: CommonService, private alertSerive: AlertService){ 
  }
  isLogged: boolean;

  ngOnInit() {
    //console.log("APP INIT");
    if(localStorage.getItem('apiToken') != null){
      //console.log("API Token Got");
    }else{
      this.commonService.apiAuthenticate().subscribe(
        (result) => {
          //console.log(result);
          if(result['responseStatus'] == 'Ok'){
            localStorage.setItem('apiToken',result['token']);
          }else{
            this.alertSerive.create("API Error","error",0,"API Authentication Failed");
          }
        },
        (error) => {
          //console.log("E::"+error);
          this.alertSerive.create("API Error","error",0,error);
        }
      )
    }
    if (localStorage.getItem('isLogged')) {
      this.isLogged = true;
    } else {
      this.isLogged = false
    }
  }
}
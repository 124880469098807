import { Component, OnInit, NgZone } from "@angular/core";
import { trigger, transition, style, state, animate } from "@angular/animations";
import { AlertService } from "../services/alert.service";

@Component({
    selector:"app-alert",
    templateUrl:"alert.component.html",
    styleUrls:["alert.component.scss"],
    animations: [
        trigger('dialog',[
            transition('void => *',[
                style({transform: 'scale3d(.3,.3,.3)'}),
                animate(100)
            ]),
            transition('void => *',
                animate(100,style({transform: 'scale3d(.3,.3,.3)'})))
        ])
    ]
})

export class AlertComponent implements OnInit {
    modalStatus: boolean;

    title:string;
    type:string;
    time:number;
    body:string;

    color:string;
    backColor:string;
    callBackTimerId:any;
    dataObj:Object = { callback: (params?:any)=>void { } };
    constructor(private alertService: AlertService, private _ngZone: NgZone) {}

    ngOnInit(){
        this.alertService.alertSettings$.subscribe(
            (data) => {
                this.title = data.title;
                this.type = data.type;
                this.time = data.time;
                this.body = data.body;

                //Show Alert

                this.modalStatus = true;
                if(this.time > 0){
                    this.callBackTimerId = setTimeout(()=>
                        this._ngZone.run(()=>{
                            this.modalStatus = false;
                            if(data.callback){
                                data.callback();
                            }
                        }
                        ),this.time
                    )
                }
                this.dataObj = data;
            }
        );
    }

    //Close Alert 
    closeAlert(){
        clearTimeout(this.callBackTimerId);
        this.modalStatus = false;
        if(this.dataObj['callback']){
            this.dataObj['callback']();
        }
    }
}
import { CommonModule } from '@angular/common';  
import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LoginComponent } from './modules/common/login/login.component';
import { AuthGuard } from './shared/auth/auth.guard';

const appRoutes: Routes = [
      {
            path: '',
            redirectTo: '/login', 
            pathMatch: 'full'
      },
      {
            path: 'login',
            component: LoginComponent 
      }, 
      {
            path:'support',
            loadChildren: () => import('./modules/support/support.module').then(m => m.SupportModule)
      },
      {
            path:'inventory',
            loadChildren: () => import('./modules/inventory/inventory.module').then(m => m.InventoryModule)
      },
      {
            path:'candid-offers',
            loadChildren: () => import('./modules/candid-offers/candid-offers.module').then(m => m.CandidOfffersModule)
      },
      {
            path:'system',
            redirectTo: '/support', 
            pathMatch: 'full'
      },
      
];


@NgModule({
      declarations: [
            LoginComponent,
      ],
      imports: [
            CommonModule,
            BrowserModule,
            FormsModule, ReactiveFormsModule,
            RouterModule.forRoot(appRoutes)
      ],
      exports: [
            RouterModule
      ],
      providers: [
           AuthGuard,
      ],
      schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppRoutingModule { }
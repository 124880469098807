import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { environment } from '../../../environments/environment';

@Injectable()
export class InventoryService {

    public token : string;
    constructor(private http: HttpClient){ }

    // Following Is Common Event For Database Get / Add / Edit / Delete Activity
    backendAPICall(eventName:string = '',dataObj:Object = {}){
        if(eventName){
            let headers = new HttpHeaders({'Content-Type':  'application/json','Authorization' : 'Bearer '+localStorage.getItem('userToken')});
            return this.http.post(
                environment.apiCredentials.apiDomain+'/'+eventName.trim(),
                JSON.stringify(dataObj),
                { headers: headers }
            );
        }
    }
}
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { Subject } from "rxjs";


@Injectable()

export class ListActionsComponentService {
    private subjects: Subject<string>[] = [];

    publish(eventName: string){
        //console.log('publish eventName',eventName);
        //ensure a subject for the event name exist
        this.subjects[eventName] = this.subjects[eventName] || new Subject();

        //publish event
        this.subjects[eventName].next();
    }

    on(eventName:string): Observable<any> {
        //console.log('onEventName',eventName);
        //ensure subject for the event name exists
        this.subjects[eventName] = this.subjects[eventName] || new Subject();

        //return observable
        return this.subjects[eventName].asObservable();
    }
}
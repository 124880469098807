import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { AlertService } from "./alert.service";
import { environment } from '../../../environments/environment';
import { forkJoin } from "rxjs";

@Injectable()
export class SupportService {

    public token : string;
    constructor(private http: HttpClient, private alertService: AlertService){ }

    // Following Is Common Event For Database Get / Add / Edit / Delete Activity
    backendAPICall(eventName:string = '',dataObj:Object = {}){
        if(eventName){
            let headers = new HttpHeaders({'Content-Type':  'application/json','Authorization' : 'Bearer '+localStorage.getItem('userToken')});
            return this.http.post(
                environment.apiCredentials.apiDomain+'/support/'+eventName.trim(),
                JSON.stringify(dataObj),
                { headers: headers }
            );
        }
    }
    
    getCities(filter:object = {}){
        let headers = new HttpHeaders({'Content-Type':  'application/json','Authorization' : 'Bearer '+localStorage.getItem('userToken')});
        return this.http.post(
              environment.apiCredentials.apiDomain+'/support/getCities',
              JSON.stringify(filter),
              { headers: headers }
        );
    }

    saveCity(cityObj){
        let postEvent = 'addCity';
        if(cityObj.cityId !== null){
            postEvent = 'updateCity';
        }
        let headers = new HttpHeaders({'Content-Type':  'application/json','Authorization' : 'Bearer '+localStorage.getItem('userToken')});
        return this.http.post(
              environment.apiCredentials.apiDomain+'/support/'+postEvent,
              JSON.stringify(cityObj),
              { headers: headers }
        );
    }

    deleteCity(cityObj){
        let headers = new HttpHeaders({'Content-Type':  'application/json','Authorization' : 'Bearer '+localStorage.getItem('userToken')});
        return this.http.post(
              environment.apiCredentials.apiDomain+'/support/deleteCity',
              JSON.stringify(cityObj),
              { headers: headers }
        );
    }

    //Following method is for the service call of client module access data
    getClientModuleAccess(filter:object = {}){
        let headers = new HttpHeaders({'Content-Type':  'application/json','Authorization' : 'Bearer '+localStorage.getItem('userToken')});
        return this.http.post(
              environment.apiCredentials.apiDomain+'/support/getClientModuleAccess',
              JSON.stringify(filter),
              { headers: headers }
        );
    }

    // Following method is for the service call of add / update the client module access data
    saveClientModuleAccess(clientModuleAccessObj){
        let postEvent = 'addClientModuleAccess';
        if(clientModuleAccessObj.clientModuleAccessId !== null){
            postEvent = 'updateClientModuleAccess';
        }
        let headers = new HttpHeaders({'Content-Type':  'application/json','Authorization' : 'Bearer '+localStorage.getItem('userToken')});
        return this.http.post(
              environment.apiCredentials.apiDomain+'/support/'+postEvent,
              JSON.stringify(clientModuleAccessObj),
              { headers: headers }
        );
    }

    // Following method is for the service call of delete client module access data
    deleteClientModuleAccess(clientModuleAccessObj){
        let headers = new HttpHeaders({'Content-Type':  'application/json','Authorization' : 'Bearer '+localStorage.getItem('userToken')});
        return this.http.post(
              environment.apiCredentials.apiDomain+'/support/deleteClientModuleAccess',
              JSON.stringify(clientModuleAccessObj),
              { headers: headers }
        );
    }

    //Following method is for the service call of user module privileges data
    getUserModulePrivileges(filter:object = {}){
        let headers = new HttpHeaders({'Content-Type':  'application/json','Authorization' : 'Bearer '+localStorage.getItem('userToken')});
        return this.http.post(
              environment.apiCredentials.apiDomain+'/support/getUserModulePrivileges',
              JSON.stringify(filter),
              { headers: headers }
        );
    }

    // Following method is for the service call of add / update the user module privileges data
    saveUserModulePrivileges(userModulePrivilegesObj){
        let postEvent = 'addUserModulePrivileges';
        if(userModulePrivilegesObj.userModulePrivilegeId !== null){
            postEvent = 'updateUserModulePrivileges';
        }
        let headers = new HttpHeaders({'Content-Type':  'application/json','Authorization' : 'Bearer '+localStorage.getItem('userToken')});
        return this.http.post(
              environment.apiCredentials.apiDomain+'/support/'+postEvent,
              JSON.stringify(userModulePrivilegesObj),
              { headers: headers }
        );
    }

    // Following method is for the service call of delete user module privileges data
    deleteUserModulePrivileges(userModulePrivilegesObj){
        let headers = new HttpHeaders({'Content-Type':  'application/json','Authorization' : 'Bearer '+localStorage.getItem('userToken')});
        return this.http.post(
              environment.apiCredentials.apiDomain+'/support/deleteUserModulePrivileges',
              JSON.stringify(userModulePrivilegesObj),
              { headers: headers }
        );
    }

    //Following method is for the service call of user group module privileges data
    getUserGroupModulePrivileges(filter:object = {}){
        let headers = new HttpHeaders({'Content-Type':  'application/json','Authorization' : 'Bearer '+localStorage.getItem('userToken')});
        return this.http.post(
              environment.apiCredentials.apiDomain+'/support/getUserGroupModulePrivileges',
              JSON.stringify(filter),
              { headers: headers }
        );
    }

    // Following method is for the service call of add / update the user group module privileges data
    saveUserGroupModulePrivileges(userModulePrivilegesObj){
        let postEvent = 'addUserGroupModulePrivileges';
        if(userModulePrivilegesObj.userGroupModulePrivilegeId !== null){
            postEvent = 'updateUserGroupModulePrivileges';
        }
        let headers = new HttpHeaders({'Content-Type':  'application/json','Authorization' : 'Bearer '+localStorage.getItem('userToken')});
        return this.http.post(
              environment.apiCredentials.apiDomain+'/support/'+postEvent,
              JSON.stringify(userModulePrivilegesObj),
              { headers: headers }
        );
    }

    // Following method is for the service call of delete user group module privileges data
    deleteUserGroupModulePrivileges(userModulePrivilegesObj){
        let headers = new HttpHeaders({'Content-Type':  'application/json','Authorization' : 'Bearer '+localStorage.getItem('userToken')});
        return this.http.post(
              environment.apiCredentials.apiDomain+'/support/deleteUserGroupModulePrivileges',
              JSON.stringify(userModulePrivilegesObj),
              { headers: headers }
        );
    }

    //Following method is for the service call of user company privileges list data
    getUserCompanyPrivilegesList(filter:object = {}){
        let headers = new HttpHeaders({'Content-Type':  'application/json','Authorization' : 'Bearer '+localStorage.getItem('userToken')});
        return this.http.post(
              environment.apiCredentials.apiDomain+'/support/getUserCompanyPrivilegesList',
              JSON.stringify(filter),
              { headers: headers }
        );
    }

    // Following method is for the service call of add / update the user company privileges data
    saveUserCompanyPrivileges(userCompanyPrivilegesObj){
        let postEvent = 'addUserCompanyPrivileges';
        if(userCompanyPrivilegesObj.userCompanyPrivilegeId !== null){
            postEvent = 'updateUserCompanyPrivileges';
        }
        let headers = new HttpHeaders({'Content-Type':  'application/json','Authorization' : 'Bearer '+localStorage.getItem('userToken')});
        return this.http.post(
              environment.apiCredentials.apiDomain+'/support/'+postEvent,
              JSON.stringify(userCompanyPrivilegesObj),
              { headers: headers }
        );
    }

    // Following method is for the service call of delete user company privileges data
    deleteUserCompanyPrivileges(userCompanyPrivilegesObj){
        let headers = new HttpHeaders({'Content-Type':  'application/json','Authorization' : 'Bearer '+localStorage.getItem('userToken')});
        return this.http.post(
              environment.apiCredentials.apiDomain+'/support/deleteUserCompanyPrivileges',
              JSON.stringify(userCompanyPrivilegesObj),
              { headers: headers }
        );
    }
    
    //Following method is for the service call of user allowed companies
    getUserAllowedCompanies(filter:object = {}){
        let headers = new HttpHeaders({'Content-Type':  'application/json','Authorization' : 'Bearer '+localStorage.getItem('userToken')});
        return this.http.post(
              environment.apiCredentials.apiDomain+'/support/getUserAllowedCompanies',
              JSON.stringify(filter),
              { headers: headers }
        );
    }

    //Following method is for the service call of user group company privileges list data
    getUserGroupCompanyPrivilegesList(filter:object = {}){
        let headers = new HttpHeaders({'Content-Type':  'application/json','Authorization' : 'Bearer '+localStorage.getItem('userToken')});
        return this.http.post(
              environment.apiCredentials.apiDomain+'/support/getUserGroupCompanyPrivilegesList',
              JSON.stringify(filter),
              { headers: headers }
        );
    }

    // Following method is for the service call of add / update the user group company privileges data
    saveUserGroupCompanyPrivileges(userCompanyPrivilegesObj){
        let postEvent = 'addUserGroupCompanyPrivileges';
        if(userCompanyPrivilegesObj.userGroupCompanyPrivilegeId !== null){
            postEvent = 'updateUserGroupCompanyPrivileges';
        }
        let headers = new HttpHeaders({'Content-Type':  'application/json','Authorization' : 'Bearer '+localStorage.getItem('userToken')});
        return this.http.post(
              environment.apiCredentials.apiDomain+'/support/'+postEvent,
              JSON.stringify(userCompanyPrivilegesObj),
              { headers: headers }
        );
    }

    // Following method is for the service call of delete user group company privileges data
    deleteUserGroupCompanyPrivileges(userCompanyPrivilegesObj){
        let headers = new HttpHeaders({'Content-Type':  'application/json','Authorization' : 'Bearer '+localStorage.getItem('userToken')});
        return this.http.post(
              environment.apiCredentials.apiDomain+'/support/deleteUserGroupCompanyPrivileges',
              JSON.stringify(userCompanyPrivilegesObj),
              { headers: headers }
        );
    }

    //Following method is for the service call of user menu privileges data
    getUserMenuPrivilegesList(filter:object = {}){
        let headers = new HttpHeaders({'Content-Type':  'application/json','Authorization' : 'Bearer '+localStorage.getItem('userToken')});
        return this.http.post(
              environment.apiCredentials.apiDomain+'/support/getUserMenuPrivilegesList',
              JSON.stringify(filter),
              { headers: headers }
        );
    }

    //Following method is for the service call of user / group menu privileges data
    getMenuPrivileges(filter:object = {}){
        let postEvent = 'getUserMenuPrivileges';
        if(filter['menuPrivilegeFor'] === 'G'){
            postEvent = 'getUserGroupMenuPrivileges';
        }
        let headers = new HttpHeaders({'Content-Type':  'application/json','Authorization' : 'Bearer '+localStorage.getItem('userToken')});
        return this.http.post(
            environment.apiCredentials.apiDomain+'/support/'+postEvent,
            JSON.stringify(filter),
            { headers: headers }
        );
    }

    

    // Following method is for the service call of add / update the user menu privileges data
    saveUserMenuPrivileges(userMenuPrivilegesObj){
        let postEvent = 'addUserMenuPrivileges';
        if(userMenuPrivilegesObj.userMenuPrivilegeId !== null){
            postEvent = 'updateUserMenuPrivileges';
        }
        let headers = new HttpHeaders({'Content-Type':  'application/json','Authorization' : 'Bearer '+localStorage.getItem('userToken')});
        return this.http.post(
              environment.apiCredentials.apiDomain+'/support/'+postEvent,
              JSON.stringify(userMenuPrivilegesObj),
              { headers: headers }
        );
    }

    // Following method is for the service call of delete user menu privileges data
    deleteUserMenuPrivileges(userMenuPrivilegesObj){
        let headers = new HttpHeaders({'Content-Type':  'application/json','Authorization' : 'Bearer '+localStorage.getItem('userToken')});
        return this.http.post(
              environment.apiCredentials.apiDomain+'/support/deleteUserMenuPrivileges',
              JSON.stringify(userMenuPrivilegesObj),
              { headers: headers }
        );
    }

    //Following method is for the service call of user group menu privileges data
    getUserGroupMenuPrivilegesList(filter:object = {}){
        let headers = new HttpHeaders({'Content-Type':  'application/json','Authorization' : 'Bearer '+localStorage.getItem('userToken')});
        return this.http.post(
              environment.apiCredentials.apiDomain+'/support/getUserGroupMenuPrivilegesList',
              JSON.stringify(filter),
              { headers: headers }
        );
    }

    //Following method is for the service call of user group menu privileges data
    getUserGroupMenuPrivileges(filter:object = {}){
        let headers = new HttpHeaders({'Content-Type':  'application/json','Authorization' : 'Bearer '+localStorage.getItem('userToken')});
        return this.http.post(
            environment.apiCredentials.apiDomain+'/support/getUserGroupMenuPrivileges',
            JSON.stringify(filter),
            { headers: headers }
        );
    }

    

    // Following method is for the service call of add / update the user group menu privileges data
    saveUserGroupMenuPrivileges(userGroupMenuPrivilegesObj){
        let postEvent = 'addUserGroupMenuPrivileges';
        if(userGroupMenuPrivilegesObj.userGroupMenuPrivilegeId !== null){
            postEvent = 'updateUserGroupMenuPrivileges';
        }
        let headers = new HttpHeaders({'Content-Type':  'application/json','Authorization' : 'Bearer '+localStorage.getItem('userToken')});
        return this.http.post(
              environment.apiCredentials.apiDomain+'/support/'+postEvent,
              JSON.stringify(userGroupMenuPrivilegesObj),
              { headers: headers }
        );
    }

    // Following method is for the service call of delete user group menu privileges data
    deleteUserGroupMenuPrivileges(userGroupMenuPrivilegesObj){
        let headers = new HttpHeaders({'Content-Type':  'application/json','Authorization' : 'Bearer '+localStorage.getItem('userToken')});
        return this.http.post(
              environment.apiCredentials.apiDomain+'/support/deleteUserGroupMenuPrivileges',
              JSON.stringify(userGroupMenuPrivilegesObj),
              { headers: headers }
        );
    }

    //Following methos is for the service call to get the Users For Record List / Autocomplete
    getUsers(filter:Object = {}){
        let headers = new HttpHeaders({'Content-Type':  'application/json','Authorization' : 'Bearer '+localStorage.getItem('userToken')});
        return this.http.post(
              environment.apiCredentials.apiDomain+'/support/getUsers',
              JSON.stringify(filter),
              { headers: headers }
        );
    }


    // Following method is for the service call of add / update the user data
    saveUser(usersObj){
        let postEvent = 'addUser';
        if(usersObj.userId !== null){
            postEvent = 'updateUser';
        }
        let headers = new HttpHeaders({'Content-Type':  'application/json','Authorization' : 'Bearer '+localStorage.getItem('userToken')});
        return this.http.post(
              environment.apiCredentials.apiDomain+'/support/'+postEvent,
              JSON.stringify(usersObj),
              { headers: headers }
        );
    }

    // Following method is for the service call of delete user data
    deleteUser(usersObj){
        let headers = new HttpHeaders({'Content-Type':  'application/json','Authorization' : 'Bearer '+localStorage.getItem('userToken')});
        return this.http.post(
              environment.apiCredentials.apiDomain+'/support/deleteUser',
              JSON.stringify(usersObj),
              { headers: headers }
        );
    }


    //Following methos is for the service call to get the Companies For Record List / Autocomplete
    getCompanies(filter:Object = {}){
        let headers = new HttpHeaders({'Content-Type':  'application/json','Authorization' : 'Bearer '+localStorage.getItem('userToken')});
        return this.http.post(
              environment.apiCredentials.apiDomain+'/support/getCompanies',
              JSON.stringify(filter),
              { headers: headers }
        );
    }


    // Following method is for the service call of add / update the company data
    saveCompany(companyObj){
        let postEvent = 'addCompany';
        if(companyObj.companyId !== null){
            postEvent = 'updateCompany';
        }
        let headers = new HttpHeaders({'Content-Type':  'application/json','Authorization' : 'Bearer '+localStorage.getItem('userToken')});
        return this.http.post(
              environment.apiCredentials.apiDomain+'/support/'+postEvent,
              JSON.stringify(companyObj),
              { headers: headers }
        );
    }

    // Following method is for the service call of delete company data
    deleteCompany(companyObj){
        let headers = new HttpHeaders({'Content-Type':  'application/json','Authorization' : 'Bearer '+localStorage.getItem('userToken')});
        return this.http.post(
              environment.apiCredentials.apiDomain+'/support/deleteCompany',
              JSON.stringify(companyObj),
              { headers: headers }
        );
    }

    // Following method is for the service call of delete company data
    removeCompanyLogo(companyObj){
        let headers = new HttpHeaders({'Content-Type':  'application/json','Authorization' : 'Bearer '+localStorage.getItem('userToken')});
        return this.http.post(
              environment.apiCredentials.apiDomain+'/support/removeCompanyLogo',
              JSON.stringify(companyObj),
              { headers: headers }
        );
    }

    //Following methos is for the service call to get the Branches For Record List / Autocomplete
    getBranches(filter:Object = {}){
        let headers = new HttpHeaders({'Content-Type':  'application/json','Authorization' : 'Bearer '+localStorage.getItem('userToken')});
        return this.http.post(
              environment.apiCredentials.apiDomain+'/support/getBranches',
              JSON.stringify(filter),
              { headers: headers }
        );
    }


    // Following method is for the service call of add / update the branch data
    saveBranch(branchObj){
        let postEvent = 'addBranch';
        if(branchObj.branchId !== null){
            postEvent = 'updateBranch';
        }
        let headers = new HttpHeaders({'Content-Type':  'application/json','Authorization' : 'Bearer '+localStorage.getItem('userToken')});
        return this.http.post(
              environment.apiCredentials.apiDomain+'/support/'+postEvent,
              JSON.stringify(branchObj),
              { headers: headers }
        );
    }

    // Following method is for the service call of delete branch data
    deleteBranch(branchObj){
        let headers = new HttpHeaders({'Content-Type':  'application/json','Authorization' : 'Bearer '+localStorage.getItem('userToken')});
        return this.http.post(
              environment.apiCredentials.apiDomain+'/support/deleteBranch',
              JSON.stringify(branchObj),
              { headers: headers }
        );
    }

    /* //Following methos is for the service call to get the Parties For Record List / Autocomplete
    getParties(filter:Object = {}){
        let headers = new HttpHeaders({'Content-Type':  'application/json','Authorization' : 'Bearer '+localStorage.getItem('userToken')});
        return this.http.post(
              environment.apiCredentials.apiDomain+'/support/getParties',
              JSON.stringify(filter),
              { headers: headers }
        );
    }


    // Following method is for the service call of add / update the party data
    saveParty(partyObj){
        let postEvent = 'addParty';
        if(partyObj.branchId !== null){
            postEvent = 'updateParty';
        }
        let headers = new HttpHeaders({'Content-Type':  'application/json','Authorization' : 'Bearer '+localStorage.getItem('userToken')});
        return this.http.post(
              environment.apiCredentials.apiDomain+'/support/'+postEvent,
              JSON.stringify(partyObj),
              { headers: headers }
        );
    }

    // Following method is for the service call of delete party data
    deleteParty(partyObj){
        let headers = new HttpHeaders({'Content-Type':  'application/json','Authorization' : 'Bearer '+localStorage.getItem('userToken')});
        return this.http.post(
              environment.apiCredentials.apiDomain+'/support/deleteParty',
              JSON.stringify(partyObj),
              { headers: headers }
        );
    }

    //Following methos is for the service call to get the Party Shipping Locations For Record List / Autocomplete
    getPartyShippingLocations(filter:Object = {}){
        let headers = new HttpHeaders({'Content-Type':  'application/json','Authorization' : 'Bearer '+localStorage.getItem('userToken')});
        return this.http.post(
              environment.apiCredentials.apiDomain+'/support/getPartyShippingLocations',
              JSON.stringify(filter),
              { headers: headers }
        );
    }


    // Following method is for the service call of add / update the party shipping location data
    savePartyShippingLocation(partyObj){
        let postEvent = 'addPartyShippingLocation';
        if(partyObj.branchId !== null){
            postEvent = 'updatePartyShippingLocation';
        }
        let headers = new HttpHeaders({'Content-Type':  'application/json','Authorization' : 'Bearer '+localStorage.getItem('userToken')});
        return this.http.post(
              environment.apiCredentials.apiDomain+'/support/'+postEvent,
              JSON.stringify(partyObj),
              { headers: headers }
        );
    }

    // Following method is for the service call of delete party data
    deletePartyShippingLocation(partyObj){
        let headers = new HttpHeaders({'Content-Type':  'application/json','Authorization' : 'Bearer '+localStorage.getItem('userToken')});
        return this.http.post(
              environment.apiCredentials.apiDomain+'/support/deleteParty',
              JSON.stringify(partyObj),
              { headers: headers }
        );
    }
 */
    //Following methos is for the service call to get the Departments For Record List / Autocomplete
    getDepartments(filter:Object = {}){
        let headers = new HttpHeaders({'Content-Type':  'application/json','Authorization' : 'Bearer '+localStorage.getItem('userToken')});
        return this.http.post(
              environment.apiCredentials.apiDomain+'/support/getDepartments',
              JSON.stringify(filter),
              { headers: headers }
        );
    }


    // Following method is for the service call of add / update the department data
    saveDepartment(departmentObj){
        let postEvent = 'addDepartment';
        if(departmentObj.departmentId !== null){
            postEvent = 'updateDepartment';
        }
        let headers = new HttpHeaders({'Content-Type':  'application/json','Authorization' : 'Bearer '+localStorage.getItem('userToken')});
        return this.http.post(
              environment.apiCredentials.apiDomain+'/support/'+postEvent,
              JSON.stringify(departmentObj),
              { headers: headers }
        );
    }

    // Following method is for the service call of delete department data
    deleteDepartment(departmentObj){
        let headers = new HttpHeaders({'Content-Type':  'application/json','Authorization' : 'Bearer '+localStorage.getItem('userToken')});
        return this.http.post(
              environment.apiCredentials.apiDomain+'/support/deleteDepartment',
              JSON.stringify(departmentObj),
              { headers: headers }
        );
    }

    //Following method is for the service call to get the Users For Record List / Autocomplete
    getUserGroups(filter:Object = {}){
        let headers = new HttpHeaders({'Content-Type':  'application/json','Authorization' : 'Bearer '+localStorage.getItem('userToken')});
        return this.http.post(
              environment.apiCredentials.apiDomain+'/support/getUserGroups',
              JSON.stringify(filter),
              { headers: headers }
        );
    }


    // Following method is for the service call of add / update the user data
    saveUserGroup(usersGroupObj){
        let postEvent = 'addUserGroup';
        if(usersGroupObj.groupId !== null){
            postEvent = 'updateUserGroup';
        }
        let headers = new HttpHeaders({'Content-Type':  'application/json','Authorization' : 'Bearer '+localStorage.getItem('userToken')});
        return this.http.post(
              environment.apiCredentials.apiDomain+'/support/'+postEvent,
              JSON.stringify(usersGroupObj),
              { headers: headers }
        );
    }

    // Following method is for the service call of delete user data
    deleteUserGroup(usersGroupObj){
        let headers = new HttpHeaders({'Content-Type':  'application/json','Authorization' : 'Bearer '+localStorage.getItem('userToken')});
        return this.http.post(
              environment.apiCredentials.apiDomain+'/support/deleteUserGroup',
              JSON.stringify(usersGroupObj),
              { headers: headers }
        );
    }

    // Following call is for getting the list of app modules 
    getModules(filter:object = {}) {
        let headers = new HttpHeaders({'Content-Type':  'application/json','Authorization' : 'Bearer '+localStorage.getItem('userToken')});
        return this.http.post(
              environment.apiCredentials.apiDomain+'/system/getModules',
              JSON.stringify(filter),
              { headers: headers }
        );
    }

    // Following call is getting the Client list for client autocomplete.
    getClientsForAutoComplete(filter:object = {}) {
        let headers = new HttpHeaders({'Content-Type':  'application/json','Authorization' : 'Bearer '+localStorage.getItem('userToken')});
        return this.http.post(
              environment.apiCredentials.apiDomain+'/system/getClientsForAutoComplete',
              JSON.stringify(filter),
              { headers: headers }
        );
    }
    
    // Following call is for getting the list of client allowed modules 
    getClientAllowedModules(filter:object = {}) {
        let headers = new HttpHeaders({'Content-Type':  'application/json','Authorization' : 'Bearer '+localStorage.getItem('userToken')});
        return this.http.post(
              environment.apiCredentials.apiDomain+'/support/getClientAllowedModules',
              JSON.stringify(filter),
              { headers: headers }
        );
    }

    // Following call is for getting the list of user allowed modules 
    getUserAllowedModules(filter:object = {}) {
        let headers = new HttpHeaders({'Content-Type':  'application/json','Authorization' : 'Bearer '+localStorage.getItem('userToken')});
        return this.http.post(
              environment.apiCredentials.apiDomain+'/support/getUserAllowedModules',
              JSON.stringify(filter),
              { headers: headers }
        );
    }

    // Following is the service call for the initial data required by Client Module Access Form for autocomplete
    getClientModuleAccessFormData() : Observable<any[]> {
        let headers = new HttpHeaders({'Content-Type':  'application/json','Authorization' : 'Bearer '+localStorage.getItem('userToken')});
        let clientsResponse = this.getClientsForAutoComplete({'status':'A'});
        let appModuleResponse = this.getModules({'status':'A'});
        return forkJoin([clientsResponse,appModuleResponse]);
    }

    // Following is the service call for the initial data required by User Module Access Form for autocomplete
    getUserModulePrivilegesFormData(filter) : Observable<any[]> {
        let headers = new HttpHeaders({'Content-Type':  'application/json','Authorization' : 'Bearer '+localStorage.getItem('userToken')});
        let appModuleResponse:any = [];
        if(filter.selectedClientCode !== ''){
            appModuleResponse = this.getClientAllowedModules({'clientCode':filter.selectedClientCode,'status':'A'});
        }else{
            appModuleResponse = this.getUserAllowedModules({'status':'A'});
        }
        let userResponse = this.getUsers({'forAutoComplete':'Y','clientCode':filter.selectedClientCode,'status':'A'});
        return forkJoin([appModuleResponse, userResponse]);
    }

    // Following is the service call for the initial data required by User Group Module Access Form for autocomplete
    getUserGroupModulePrivilegesFormData(filter) : Observable<any[]> {
        let headers = new HttpHeaders({'Content-Type':  'application/json','Authorization' : 'Bearer '+localStorage.getItem('userToken')});
        let appModuleResponse:any = [];
        if(filter.selectedClientCode !== ''){
            appModuleResponse = this.getClientAllowedModules({'clientCode':filter.selectedClientCode,'status':'A'});
        }else{
            appModuleResponse = this.getUserAllowedModules({'status':'A'});
        }
        let userGroupResponse = this.getUserGroups({'forAutoComplete':'Y','clientCode':filter.selectedClientCode,'status':'A'});
        return forkJoin([appModuleResponse, userGroupResponse]);
    }

    // Following is the service call for the initial data required by User Company Privilege Form for autocomplete
    getUserCompanyPrivilegesFormData(filter) : Observable<any[]> {
        let headers = new HttpHeaders({'Content-Type':  'application/json','Authorization' : 'Bearer '+localStorage.getItem('userToken')});
        let filters = {};
        if(filter.selectedClientCode !== ''){
            filters = {'clientCode':filter.selectedClientCode,'status':'A'}
        }else{
            filters = {'status':'A'};
        }
        let allowedCompaniesResponse = this.getUserAllowedCompanies(filters);
        let userResponse = this.getUsers({'forAutoComplete':'Y','clientCode':filter.selectedClientCode,'status':'A'});
        return forkJoin([allowedCompaniesResponse, userResponse]);
    }

    // Following is the service call for the initial data required by User Group Company Privilege Form for autocomplete
    getUserGroupCompanyPrivilegesFormData(filter) : Observable<any[]> {
        let headers = new HttpHeaders({'Content-Type':  'application/json','Authorization' : 'Bearer '+localStorage.getItem('userToken')});
        let filters = {};
        if(filter.selectedClientCode !== ''){
            filters = {'clientCode':filter.selectedClientCode,'status':'A'}
        }else{
            filters = {'status':'A'};
        }
        let allowedCompaniesResponse = this.getUserAllowedCompanies(filters);
        let userGroupResponse = this.getUserGroups({'forAutoComplete':'Y','clientCode':filter.selectedClientCode,'status':'A'});
        return forkJoin([allowedCompaniesResponse, userGroupResponse]);
    }


    
}



import { CommonModule, APP_BASE_HREF } from '@angular/common';  
import { RouterModule, GuardsCheckEnd, NavigationEnd, NavigationCancel, NavigationError } from '@angular/router';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';  
import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'; 
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import 'rxjs';
import { MaterialModule } from "./shared/modules/material/material.module";
import { AppRoutingModule } from './app-routing.module';
import { ToastrModule } from 'ngx-toastr';
import { NgxSpinnerModule } from 'ngx-spinner';
import { SharedModule } from "./shared/shared.module";
import { NgProgressModule } from 'ngx-progressbar';
import { NgProgressHttpModule } from 'ngx-progressbar/http';
import { NgProgressRouterModule } from 'ngx-progressbar/router';
import { AppComponent } from './app.component';
import { AlertService } from "./shared/services/alert.service";
import { ServiceErrorInterceptor } from "./shared/services/service-error.interceptor";
import { CommonService } from './shared/services/common.service';
import { SupportService } from "./shared/services/support.service";
import { InventoryService } from "./shared/services/inventory.service";
import { SystemService } from "./shared/services/system.service";
import { CandidOffersService } from './shared/services/candid-offers.service';
import { ListActionsComponentService } from "./shared/services/list-actions-component.service";


@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    HttpClientModule,
    MaterialModule,
    ToastrModule.forRoot({
      timeOut: 10000,
      positionClass: 'toast-bottom-right',
      preventDuplicates: true,
    }),
    NgxSpinnerModule,
    SharedModule,
    NgProgressModule,
    NgProgressHttpModule,
    NgProgressRouterModule.withConfig({
      startEvents: [GuardsCheckEnd],
      completeEvents: [NavigationEnd,NavigationCancel,NavigationError],
      delay: 1000,
      id: 'router-progressbar'
    }),
  ],
  exports:[
  ],
  providers: [
    CommonService,
    SupportService,
    InventoryService,
    SystemService,
    CandidOffersService,
    ListActionsComponentService,
    AlertService,
    {
      provide:HTTP_INTERCEPTORS,
      useClass:ServiceErrorInterceptor,
      multi:true 
    }
   ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppComponent],
  
})
export class AppModule { }
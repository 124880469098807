import { Injectable } from '@angular/core';
import { AlertService } from "./alert.service";
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { Router } from '@angular/router';

@Injectable()
export class ServiceErrorInterceptor implements HttpInterceptor{

    constructor(private router: Router, private alertService:AlertService){ }
    
    intercept(request: HttpRequest<any>, next:HttpHandler): Observable<HttpEvent<any>>{
        return next.handle(request)
            .pipe(
                map((event: HttpEvent<any>)=>{
                    if(event instanceof HttpResponse){
                        //console.log('event ->>> ',event);
                    }
                    return event;               
                }),
                catchError((error: HttpErrorResponse)=>{
                    
                    if (error.error instanceof ErrorEvent) {
                        // A client-side or network error occurred. Handle it accordingly.
                        console.error('An error occurred:', error.error.message);
                        this.alertService.create("Server Error","error",0,error.error.message);
                    } else {
                        // The backend returned an unsuccessful response code.
                        // The response body may contain clues as to what went wrong,
                        if(error.status === 500){
                            //alert("API Error Occured");
                        }
                        /*  console.error(
                        `Backend returned code ${error.status}, ` +
                        `body was: ${error.message}`);  */
                        if(error.status === 0){
                            this.alertService.create("API Server Error","error",0,"API Server Not Responding");
                        }else{
                            this.alertService.create("Server Error","error",0,error.message);
                        }
                        if(error.status === 401){
                            this.alertService.create("Session Expired","error",20000,"Your session expired, please login again.",()=>{
                                //console.log('callback');
                                localStorage.removeItem('userToken');
                                localStorage.removeItem('isLogged');
                                localStorage.removeItem('userData');
                                localStorage.removeItem('currentBranchData');
                                localStorage.removeItem('currentCompanyData');
                                localStorage.removeItem('currentModuleData');
                                localStorage.removeItem('currentFinancialYear');
                                localStorage.removeItem('menuActionsData');
                                this.router.navigate(['/login']);
                            });
                        }
                    }
                    // return an observable with a user-facing error message
                    return throwError('Backend API Failed; please try again later.');
                })
            );
    }    
}